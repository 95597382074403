import React, { useState } from "react";

import img1 from "../assets/images/pexels-andrea-piacquadio-935750.jpg";
import "./EmailCapture.css";

function EmailCapture({done,firstName,setFirstName,email,setEmail}) {
    // const [firstName, setFirstName] = useState("")
    // const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false);


    const submit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        await fetch("https://app.seo4.fun/api/add_interp", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, firstName }),
        })
        done()


    }
  return (
    <>
      <div className="main text-center">
        <main className="form-signin w-200 m-auto">
          <div className="card">
          <img src={img1} className="card-img-top" alt="..."/>
            <div className="card-body">
              <form name="emailForm" onSubmit={e => submit(e)}>
                <h1 className="h1 mb-3 fw-normal">
                  Découvrez la signification cachée de vos rêves dès
                  maintenant !
                </h1>
                <p className="text-muted">
                  Entrez votre adresse e-mail pour obtenir votre interprétation
                  de rêve personnalisée et découvrez les secrets que votre
                  subconscient essaie de vous révéler !
                </p>
                
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    placeholder="Mon prénom"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <label htmlFor="firstName">Mon Prénom</label>
                </div>

                <div className="form-floating mt-3">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Mon email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="email">Mon Email</label>
                </div>

                {isLoading && (
                <button
                className="w-100 btn btn-lg btn-primary mt-5"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-grow spinner-grow-sm"
                    style={{ marginRight: "5px" }}
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Patientez un instant...
                </button>
              )}

                {!isLoading && (
                <button
                  className="w-100 btn btn-lg btn-primary mt-5"
                  type="submit"
                  disabled={!firstName || !email}
                >
                  Obtenir mon analyse de rêve personnalisée
                </button>
                )}
              </form>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default EmailCapture;
