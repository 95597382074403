import React, { useState } from "react";
import "./StarRating.css";
import {isMobile} from 'react-device-detect';

function StarRating({firstName,email}) {
  // const [rating, setRating] = useState(0);
  // const [hover, setHover] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);

  const onSetRating = async (e) => {
    e.preventDefault();
    if (!isDisabled) {
      const t = e.target;

      await fetch("https://app.seo4.fun/api/send_rating", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ rating: t.value,firstName,email }),
      });
      setIsDisabled(true);
    }
  };
  return (
    <>
      <h2>Que pensez-vous de cette interprétation ?</h2>
      <small>
      Contribuez à l'amélioration de notre service en attribuant une note grâce aux étoiles situées ci-dessous.
      </small>
      {isDisabled && <h2 style={{ marginTop: "1em" }}>Merci ❤️</h2>}
      {!isDisabled && 
      <div className="rating" style={{fontSize:isMobile?"16vw":"3vw"}}>
        <input
          disabled={isDisabled ? "disabled" : ""}
          type="radio"
          name="rating"
          value="5"
          id="5"
          onChange={(e) => onSetRating(e)}
        />
        <label htmlFor="5">☆</label>
        <input
          disabled={isDisabled ? "disabled" : ""}
          type="radio"
          name="rating"
          value="4"
          id="4"
          onChange={(e) => onSetRating(e)}
        />
        <label htmlFor="4">☆</label>
        <input
          disabled={isDisabled ? "disabled" : ""}
          type="radio"
          name="rating"
          value="3"
          id="3"
          onChange={(e) => onSetRating(e)}
        />
        <label htmlFor="3">☆</label>
        <input
          disabled={isDisabled ? "disabled" : ""}
          type="radio"
          name="rating"
          value="2"
          id="2"
          onChange={(e) => onSetRating(e)}
        />
        <label htmlFor="2">☆</label>
        <input
          disabled={isDisabled ? "disabled" : ""}
          type="radio"
          name="rating"
          value="1"
          id="1"
          onChange={(e) => onSetRating(e)}
        />
        <label htmlFor="1">☆</label>
      </div>
      }
    </>
  );
}

export default StarRating;
