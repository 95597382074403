import React, { useState } from "react";
import TypeWriterEffect from "react-typewriter-effect";
import { StarRating } from "../StarRating";

function InterpretForm({firstName,email}) {
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isfinish, setIsFinish] = useState(false);
  const [responseInterp, setResponseInterp] = useState("");
  
  const cookieValue = document.cookie.split("; ").find((row) => row.startsWith("interp="))?.split("=")[1];
  const [alreadyDone, setAlreadyDone] = useState(cookieValue?true:false);

  const submit = async (e) => {
    e.preventDefault();
    setResponseInterp("");
    setIsLoading(true);
    document.cookie = "interp=true; SameSite=None; Secure";
    const response = await fetch("https://app.seo4.fun/api/interpretation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ reve: text,firstName,email,alreadyDone }),
    });

    setIsLoading(false);
    const responseJson = await response.json();
    setResponseInterp(responseJson["message"]);
    setIsFinish(true);
  };

  const handleInputChange = async (e) => {
    const text = e.target.value;
    setText(text);
  };
  return (
    <>
      <div className="container-fluid">
        <div
          className="row align-items-start text-center"
          style={{ marginTop: "100px" }}
        >
          <h1>L'interprétation des Rêves immédiatement et gratuitement</h1>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: "20px" }}
          >
            {!responseInterp && 
            <form action="" onSubmit={(e) => submit(e)}>
              <label htmlFor="votreReve" className="form-label">
                Bonjour {firstName}, décrivez votre rêve en quelques mots
              </label>
              <input
                autoFocus
                type="text"
                className="form-control rounded-pill form-control-lg"
                id="votreReve"
                aria-describedby="votreReveHelp"
                value={text}
                onChange={handleInputChange}
                required
              />
              <div id="votreReveHelp" className="form-text">
                Décrivez votre rêve en quelques mots afin de nous permettre de
                l'interpréter le plus précisément possible.
              </div>
              <br />

              {isLoading  && (
                <button
                  className="btn btn-primary rounded-pill btn-lg"
                  type="button"
                  disabled
                  style={{ marginTop: "20px" }}
                >
                  <span
                    className="spinner-grow spinner-grow-sm"
                    style={{ marginRight: "5px" }}
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Nous interprétons votre rêve...
                </button>
              )}
              {!isLoading  && (
                <button
                  type="submit"
                  className="btn btn-primary rounded-pill btn-lg"
                  style={{ marginTop: "20px" }}
                  disabled={text.length === 0 || isfinish}
                >
                  Lancer l'interprétation de mon rêve
                </button>
              )}
            </form>
            }
          </div>
        </div>

        {responseInterp && (
          <div
            className="row align-items-start text-center"
            style={{ marginTop: "20px" }}
          >
                  <h3>Mon rêve : {text}</h3>
            <div className="d-flex justify-content-center">
              <div className="card col-sm-4 col-md-10 col-lg-4 col-12">
                <div className="card-body text-start">
                  <TypeWriterEffect
                    text={responseInterp}
                    typeSpeed={50}
                    textStyle={{ fontSize: "1.5rem", fontWeight: "normal" }}
                  />
                </div>
              </div>
            </div>
            <div style={{marginTop:'3em'}}>
            <StarRating firstName={firstName} email={email}/>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default InterpretForm;
