import { useState } from "react";
import { EmailCapture } from "./EmailCapture";
import { InterpretForm } from "./InterpretForm";



function App() {
  const [showForm, setShowForm] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [email, setEmail] = useState("")

  
  
  return (
    <div>
      
      {!showForm &&
      <EmailCapture done={e => setShowForm(true)} firstName={firstName} email={email} setFirstName={setFirstName} setEmail={setEmail}/>
      }
      {showForm &&
      <InterpretForm firstName={firstName} email={email}/>
      }
    </div>
  );
}

export default App;
